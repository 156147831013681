section.contact {
  background: $lightest;
  text-align: center;

  h1 {
    color: $dark-blue;
  }

  .contact-items {
    flex-direction: column;

    .card {
      margin-bottom: 1rem;
      flex: 1 1 0px;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }
}
