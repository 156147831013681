section.testimonials {
  background-color: $lightest;

  .container {
    position: relative;

    .carousel-inner {
      align-items: stretch;

      .react-multi-carousel-item {
        .item {
          height: 100%;
        }
      }

      .testimonial-card {
        height: 100%;

        @include media-breakpoint-up(md) {
          padding: 1rem;
        }

        .card {
          padding: 2rem;
          height: 100%;

          .card-body {
            display: flex;
            flex-direction: column;
            align-items: start;

            .logo-wrapper {
              display: flex;
              height: 85px;
              align-items: center;
            }

            img.logo {
              max-width: 180px;
              max-height: 35px;
              width: auto;
              height: auto;
              display: block;

              transition: all 300ms ease;
              filter: saturate(0%) brightness(70%);

              &:hover {
                filter: saturate(100%) brightness(100%);
              }
            }

            img.person {
              max-width: 35px;
              max-height: 35px;
              width: auto;
              height: auto;
              display: block;

              transition: all 300ms ease;
              filter: saturate(0%) brightness(70%);

              &:hover {
                filter: saturate(100%) brightness(100%);
              }
            }

            & > div {
              gap: 8px;
            }

            .mini-quote {
              margin-top: 6px;
              max-width: 18px
            }

            p.subheading {
              padding: 1rem 0;
              flex: 1;
              line-height: 1.5rem;
            }

            h4 {
              font-weight: bold;
            }

            div.position {
              color: $grey;
              font-size: $font-size-sm;
            }

            .right {
              display: flex;
              justify-content: flex-end;
              width: 100%;
            }
          }
        }
      }
    }

    a.carousel-control-prev {
      left: 13px;

      @include media-breakpoint-up(md) {
        left: 0;
      }
    }

    a.carousel-control-next {
      right: 13px;

      @include media-breakpoint-up(md) {
        right: 0;
      }
    }
  }
}
