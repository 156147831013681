.title-with-3-buttons {
  @include white-headers;

  h2 {
    color: $blue;
    text-align: center;
  }

  .btn {
    width: 12.31rem;
    font-weight: 500;
    font-size: 1rem;
  }

  .btn-outline-light {
    background-color: transparent;
    color: $black;
    border-color: $black;
  }

  hr {
    background: darkgrey;
    height: 0.15rem;
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }
}
