@import './variables';

@import '~bootstrap/scss/bootstrap';

@import 'fonts';

@import 'headers';
@import 'read-more';
@import 'indication';
@import 'basic-styles';
@import 'animations';

@import 'layout/layout';

@import './components/author';
@import './components/buttons';
@import './components/card';
@import './components/footer';
@import './components/name-with-date';
@import './components/navbar';
@import './components/breadcrumb';
@import './components/top-message';
@import './components/homepage-hero';
@import './components/testimonial';
@import './components/feature-boxes';
@import './components/clients-logos';
@import './components/text-with-image';
@import './components/call-to-action';
@import './components/pricing-selector';
@import './components/faqs';
@import './components/hero-with-video';
@import './components/page-menu';
@import './components/title-with-3-buttons';
@import './components/top-promo';
@import './components/contact-us';
@import './components/offcanvas';

@import './pages/404';
@import './pages/blog';
@import './pages/roadmap';
@import './pages/schedule-call';
@import './pages/conditions';
@import './pages/contact';

@media print {
  html {
    transform: scale(0.8);
  }
}
