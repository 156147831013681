.nav-container,
.offcanvas-body {

  /**
    * Navbar
   */
  .navbar,
  .navbar-nav {

    .container {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .navbar-toggler {
      padding: 3px 6px;

      span.navbar-toggler-icon {
        width: 1em;
        height: 1em;
      }
    }

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    margin-bottom: -1px;
    transition: 200ms;
    background-position: bottom;
    padding-bottom: 0;

    &.header-scrolled {
      box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.1);


      @include media-breakpoint-up(lg) {
        height: 4rem;
      }

      .navbar-brand {
        max-width: 180px;
      }
    }

    .navbar-brand {
      max-width: 180px;
      color: $black;
      margin-right: 2.5rem;
      display: flex;

      svg {
        background-color: transparent;
        //max-width: 125px;

        @include media-breakpoint-up(xs) {
          //max-width: 150px;
        }

        @include media-breakpoint-up(sm) {
          //max-width: 180px;
        }
      }

      &:hover {
        border-bottom: none;
      }
    }

    .dropdown {
      &.show {
        .fa-chevron-down {
          animation: spin 0.5s normal forwards ease-in-out;
        }

        .link-content {
          position: relative;

          &:before {
            content: '';
            display: block;
            width: 0;
            height: 0;
            position: absolute;
            border: 12px solid transparent;
            border-bottom: 12px solid #fff;
            left: 54px;
            bottom: -16px;
            z-index: 20;
          }
        }
      }
    }

    .dropdown-menu {
      .submenu {
        display: flex;

        .dropdown-item {
          &:hover {
            background: none !important;
          }
        }

        span.badge {
          position: relative;
          bottom: 15px;
        }

        a {
          font-weight: 700;
        }

        @include media-breakpoint-up(xl) {
          .submenu-column {
            a {
              padding: 0.5rem 0.75rem;
              white-space: nowrap !important;
            }
          }
        }

        .block {
          display: none;

          @include media-breakpoint-up(lg) {
            display: block;
            width: 400px;
          }
          @include media-breakpoint-up(xl) {
            width: 500px;
          }
          @include media-breakpoint-up(xxl) {
            width: 600px;
          }

          .menu-item {
            display: none;
            border-top-right-radius: $border-radius;
            border-bottom-right-radius: $border-radius;

            &.active {
              display: block;
              width: 100%;
              height: 100%;
            }

            &.image {
              background: $primary;
            }

            &.image-with-text {
              background: #fbfbfb;
            }

            .content {
              padding: 0.5rem;
              display: flex;
              flex-direction: column;
              height: 100%;
            }

            .flex-fill {
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
            }

            &.image {
              .img-fit {
                max-width: 100%;
                max-height: 100%;
                object-fit: contain;
              }
            }

            &.image-with-text {
              .img-fit {
                max-width: 50%;
                max-height: 100%;
                object-fit: contain;
              }

              h3 {
                color: $primary;
              }

              p {
              }

              a {
                text-decoration: none;
                text-transform: none;
              }
            }
          }
        }
      }
    }

    .nav-link {
      &.btn-secondary {
        background: var(--bs-btn-bg) !important;
      }

      &.btn {
        padding: 0.5rem 2rem;
        color: var(--bs-nav-link-color);;

        &.btn-text {
          box-shadow: none;
        }
      }
    }
  }
}

.main-menu {

  .offcanvas-body {
    justify-content: center;
  }

  .nav-signup {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  .dropdown-menu {
    padding: 0;

    @include media-breakpoint-up(md) {
      box-shadow: 1px 1px 13px 7px #2125291f;
      z-index: 10;
    }

    .big-link {
      color: var(--bs-nav-link-color);
      font-size: var(--bs-nav-link-font-size);
      font-weight: $nav-link-font-weight;
      display: flex;
      align-items: center;
      flex-direction: row;
      border: 0;

      img {
        position: relative;
        margin-right: 1rem;
        bottom: 0;
      }

      .title {
        display: flex;
        flex-direction: column;

        .subtitle {
          font-size: 0.9rem;
          font-weight: 300;
          text-transform: none;
          color: $normalgrey;
        }
      }

      &:hover {
        color: $blue;
        border-bottom: transparent;
      }
    }
  }

  .nav-link {
    border-bottom: 0.0625rem solid transparent;

    @include media-breakpoint-only(lg) {
      padding-left: 0;
      padding-right: 0;
      margin: 0 0.8rem;
    }
    @include media-breakpoint-up(lg) {
      padding-left: 0;
      padding-right: 0;
      margin: 0 1rem;
    }

    &.dropdown-toggle {
      &::after {
        content: none;
      }
    }

    &:hover {
      color: $blue;

      &:not(.dropdown-toggle):not(.big-link) {
        @include media-breakpoint-up(lg) {
          //border-bottom: 0.0625rem solid;
        }
      }
    }
  }
}

.bg-lightest {
  background-color: $lightest;
}
