/**
 * fonts
 */
$font-size-base: 1rem;
$font-family: 'Manrope', 'Roboto', sans-serif;
$font-family--numeric: 'Manrope', 'Roboto', sans-serif;

/**
 * global widths, paddings
 */
$app-max-width: 1400px;
$app-padding: 0px 24px;
$top-bar-height: 50px;

$timetracking__inner-padding: 20px;

/**
 * colours
 */
// primary colours
$blue: #007fff;
$green: #4db07a;
$red: #e55f3a;
$orange: #ffaa00;
$dark-blue: #003366;

$orange-hover: lighten($orange, 10%);

// secondary colours
$brown: #a6742b;
$golden: $orange;
$lightgreen: rgba($green, 0.4);
$lightgreenNontransparent: #b2ddca;
$lightred: rgba($red, 0.4);
$lightredNontransparent: #f5bfb0;
$lightblue: #9edbff;
$lightyellow: #fdf3c4;
$lightorange: rgba($orange, 0.4);

// bw
$white: #fff;
$graphgrey: #dfdfdf;
$formgrey: #e6e6e6;
$lightest2: #f4f4f4;
$lightest: #fafafa;
$light: #d9d9d9;
$grey: #999;
$realgrey: #ccc;
$middlegrey: #666;
$lightergrey: #969bab;
$darkergrey: #565c65;
$footergrey: #d9dbe1;
$normalgrey: #686868;
$newslettergrey: #4d4d4d;
$darkgrey: #333;
$black: #000;
$darkerBlack: #21262a;
$darkBlack: #0b0d17;

$bg-gray: #f5f8f9;

$dark: $blue;
$blue-lighter: #00b6f0;

$purple: #9b51e0;

$palette-colors: #7ac4cc, #f29199, #e0b4d1, #bfa3b8, #b6b6f2, #86babf, #d9d7ad,
#ccc883, #e5dd7e, #e5e167, #a3c7d9, #8fb2ac, #b6c2f2, #a7bbc4, #bed989,
#98d9d9, #c3c7d9;

// ---- Colors
$primary: $dark-blue;
$secondary: $orange;

$success: $green;
$info: $blue-lighter;
$warning: $orange;
$danger: $red;
$light: $lightest;
$dark: $grey;

$theme-colors: (
        "primary": $primary,
        "secondary": $secondary,
        "highlight": $blue,
        "success": $success,
        "info": $info,
        "warning": $warning,
        "danger": $danger,
        "light": $light,
        "dark": $dark
);

// --- Layout
$padding-x: 1rem;

// ---- Grid

$grid-columns: 24;
$grid-gutter-width: 30px;

$grid-breakpoints: ();
$grid-breakpoints: map-merge(
                (
                        xs: 0,
                        sm: 576px,
                        md: 768px,
                        lg: 992px,
                        xl: 1200px,
                        xxl: 1400px,
                ),
                $grid-breakpoints
);

$container-max-widths: ();
$container-max-widths: map-merge(
                (
                        sm: 700px,
                        md: 960px,
                        lg: 980px,
                        xl: 1140px,
                        xxl: 1360px,
                ),
                $container-max-widths
);

// Navbar
$navbar-padding-x: $padding-x;
$navbar-padding-y: 0.2rem;
$navbar-nav-link-padding-x: 0.25rem;

$nav-link-padding-y: 0.5rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-font-size: 0.9rem;
$nav-link-font-weight: 600;
$nav-link-color: $white;
$nav-link-hover-color: null !default;
$nav-link-transition: color 0.15s ease-in-out,
background-color 0.15s ease-in-out, border-color 0.15s ease-in-out !default;

// Buttons
$btn-color: var($white);

// Cards
$card-border-width: 0px;
$card-spacer-x: 0;
$card-spacer-y: $card-spacer-x;

$headings-font-weight: 400;

$box-shadow: 0 5px 15px 0 rgba($black, 0.1);
$box-shadow-sm: 0 3px 8 0 rgba($black, 0.05);
$box-shadow-lg: 0 8px 30px 0 rgba($black, 0.2);
$box-shadow-inset: inset 0 1px 0 rgba($black, 0.1);

// Inputs
$border-radius: 0.5rem;

// Accordion
$accordion-button-active-bg: $white;
$accordion-button-active-color: $primary;

// Dropdown
$dropdown-border-width: 0;
$dropdown-border-color: transparent;

// Carousel
$carousel-control-color: $darkgrey;
$carousel-control-width: auto;

// Fonts
$font-size-base: 1.125rem;
$font-size-sm: $font-size-base * 0.75;
$font-size-lg: $font-size-base * 1.25;

$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;

$font-weight-base: $font-weight-normal;

$line-height-base: 1.5;
$line-height-sm: 1.25;
$line-height-lg: 2;

$h1-font-size: $font-size-base * 2.5;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.5;
$h4-font-size: $font-size-base * 1.5;
$h5-font-size: $font-size-base * 1.25;
$h6-font-size: $font-size-base;


$font-size-base-mobile: 1rem;
$h1-font-size-mobile: $font-size-base * 2.5;
$h2-font-size-mobile: $font-size-base * 1.5;
$h3-font-size-mobile: $font-size-base * 1.25;
$h4-font-size-mobile: $font-size-base * 1.25;
$h5-font-size-mobile: $font-size-base * 1.125;
$h6-font-size-mobile: $font-size-base * 1;

$spacing-vertical: 1rem;
$spacing-horizontal: 3rem;

$spacing-vertical-mobile: 1rem;
$spacing-horizontal-mobile: 1.5rem;
