.initial-state {
  flex-direction: column-reverse;
  position: relative;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
  }
}

.wrapper-hero {
  position: relative;
  width: 100%;

  @include media-breakpoint-up(lg) {
    height: 380px;
  }
  @include media-breakpoint-up(xl) {
    height: 520px;
  }
}

.homepage-hero {
  background: $lightest;
  overflow: hidden;

  .hero-left {
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: -1rem;
    gap: 0.5rem;

    strong {
      @include font-size($font-size-base * 0.9);
      color: $dark-blue;
      opacity: 0.65;
      margin-bottom: -0.75rem;
    }

    @include media-breakpoint-up(md) {
      margin-top: 0;
      gap: 1rem;
    }

    @include media-breakpoint-up(lg) {
      max-width: 540px;
    }

    @include media-breakpoint-up(xl) {
      max-width: 540px;
      min-height: 520px;
    }

    h1 {
      color: $dark-blue;
      @include font-size($font-size-base * 2);

      @include media-breakpoint-up(lg) {
        @include font-size($font-size-base * 1.8);
      }

      @include media-breakpoint-up(xl) {
        @include font-size($font-size-base * 2);
        max-width: 550px;
      }


      strong {
        color: $orange;
      }

    }

    ul li {
      @include font-size($font-size-base * 0.9);
    }

    .buttons {
      display: flex;
      gap: 1rem;

      .btn {
        flex-grow: 1;
        font-weight: 700;

        @include media-breakpoint-up(md) {
          flex-grow: 0;
        }
      }
    }

    .list-group-benefits {
      flex-direction: column;
      font-size: $font-size-sm;

      li {
        @include font-size($font-size-base * 0.8);
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        gap: 0.8rem;
      }
    }
  }

  .hero-right {
    display: flex;
    justify-content: center;
    min-height: 260px;

    @include media-breakpoint-up(lg) {
      position: absolute;
      right: -100px;
      margin-top: 2rem;
      max-width: 650px;
    }

    @include media-breakpoint-up(xl) {
      margin-top: 4rem;
      max-width: 750px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 900px;
    }


    img {
      max-width: 140%;

      @include media-breakpoint-up(md) {
        max-width: 100%;
      }
    }
  }
}
