.blog {
  h2 > a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @include read;

  .blog-card {
    flex-direction: column;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    .col {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &.right {
        align-items: center;

        margin-bottom: 3rem;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
    }
  }

  .imageLink {
    align-items: center;
  }

  picture {
    //aspect-ratio: 3/2;
    display: flex;
    --image-img-height: 100%;
    --image-img-max-width: 100%;
    --image-img-max-height: 100%;
    --image-scale: 1;

    img {

    }
  }

  hr {
    margin-bottom: 2rem;
    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .blog-item {
    .basic-information {
      display: flex;
      align-content: center;
      justify-content: space-between;
      align-items: center;
      margin-top: 1rem;
      margin-bottom: 1rem;

      .author {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
          border-radius: 10rem;
          max-width: 40px;
        }

        div {
          margin-left: 0.5rem;
        }
      }
    }

    .badge {
      display: inline-block;
      margin-bottom: 1rem;

      &.blue {
        background-color: $primary;
      }

      &.yellow {
        background-color: $secondary;
      }

      &.purple {
        background-color: $purple;
      }
    }

    @include read;

    .readMore {
      text-align: right;
    }

    .card {
      margin-bottom: 2rem;
    }

  }

  &.blog-detail {
    .blog-item {
      @include blue-headers;
      max-width: 980px;
      margin-bottom: 3rem;

      .blog-content {
        img {
          max-width: 100%;
        }


      }

      .basic-information {
        margin-top: 2rem;
        margin-bottom: 2rem;
      }
    }

    h1 {
      color: $primary;

      &.title {
        text-align: center;
      }
    }

    .img-wrapper, .badge-wrapper {
      display: flex;
      justify-content: center;
    }
  }

}
