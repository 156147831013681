section.call-to-action {
  @include blue-orange;
  text-align: center;

  margin: auto 1rem;

  @include media-breakpoint-up(lg) {
    margin: 0;
  }

  @include media-breakpoint-up(lg) {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }

  @include media-breakpoint-up(xxl) {
    padding-top: 3rem;
    padding-bottom: 1rem;
  }

  .container {
    background: $blue;
    padding-top: 3rem;
    padding-bottom: 2rem;
    border-radius: 1rem;
  }

  h2 {
    color: $white;
    font-weight: 800;
    max-width: 700px;

    @include media-breakpoint-up(xl) {
      max-width: 900px;
    }

    @include media-breakpoint-up(xxl) {
      max-width: 1100px;
    }
  }

  .btn {
    margin: 2rem auto;
    font-weight: 700;

    @include media-breakpoint-up(lg) {
      font-size: 1.2rem;
      margin-bottom: 3rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 0.75rem 2rem;
      font-size: 1.3rem;
      margin: 1.5rem auto 3rem;
    }

    @include media-breakpoint-up(xxl) {
      padding: 1rem 3rem;
      font-size: 1.5rem;
      margin: 2rem auto;
    }
  }

  &.dark {
    background-color: $primary;

    img.light-mode-img {
      display: none;
    }

    img.dark-mode-img {
      display: inline-block;
    }

    h2 {
      color: $white;
      padding-top: 3rem;
    }

  }


  .list-group {
    color: $white;
    flex-direction: column;
    font-size: $font-size-sm;

    @include spacing-vertical($spacing-vertical-mobile);

    @include media-breakpoint-up(lg) {
      @include spacing-vertical($spacing-vertical);
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: center;
      gap: 1rem;
    }
  }
}
