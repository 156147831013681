.author {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  img {
    border-radius: 10rem;
    max-width: 40px;
  }

  div {
    margin-left: 0.5rem;
  }
}
