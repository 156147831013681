section.feature-boxes {
  background-color: $lightest;

  p {
    strong {
      color: $blue;
      font-weight: 600;
    }
  }

  h3 {
    @include font-size($h5-font-size-mobile);

    @include media-breakpoint-up(lg) {
      @include font-size($h5-font-size);
    }
  }

  .item {
    padding-top: 1rem;
    padding-bottom: 1rem;

    @include media-breakpoint-up(xxl) {
      padding-top: 0;
      padding-bottom: 0;
    }

    .key-image {
      position: relative;
      height: 64px;

      img {
        width: 40px;
        height: 40px;
        overflow: hidden;

        @include media-breakpoint-up(xl) {
          width: 60px;
          height: 60px;
          margin-bottom: 1rem;
        }

        @include media-breakpoint-up(xxl) {
          width: 80px;
          height: 80px;
          margin-bottom: 1.5rem;
        }

      }
    }
  }
}
