html,
body {
  font-family: $font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizelegibility;

  font-size: $font-size-base-mobile;

  @include media-breakpoint-up(xl) {
    font-size: $font-size-base;
  }
}

h1 {
  @extend %heading;
  font-weight: 700;
  //@include font-size($h1-font-size-mobile);
  //
  //@include media-breakpoint-up(lg) {
  //  @include font-size($h1-font-size);
  //}
  color: $dark-blue;
  @include font-size($font-size-base * 2);

  @include media-breakpoint-up(lg) {
    @include font-size($font-size-base * 1.8);
  }

  @include media-breakpoint-up(xl) {
    @include font-size($font-size-base * 2);
  }


  strong {
    color: $orange;
  }
}

h2 {
  @extend %heading;
  font-weight: 600;
  @include font-size($h2-font-size-mobile);

  @include media-breakpoint-up(lg) {
    @include font-size($h2-font-size);
  }
}

h3 {
  @extend %heading;
  font-weight: 600;
  @include font-size($h3-font-size-mobile);

  @include media-breakpoint-up(lg) {
    @include font-size($h3-font-size);
  }
}

h4 {
  @extend %heading;
  font-weight: 700;
  @include font-size($h4-font-size-mobile);

  @include media-breakpoint-up(lg) {
    @include font-size($h4-font-size);
  }
}

h5 {
  @extend %heading;
  font-weight: 800;
  @include font-size($h5-font-size-mobile);

  @include media-breakpoint-up(lg) {
    @include font-size($h5-font-size);
  }
}

h6 {
  @extend %heading;
  margin-bottom: 0;
  font-weight: 700;
  @include font-size($h6-font-size-mobile);

  @include media-breakpoint-up(lg) {
    @include font-size($h6-font-size);
  }
}

h1.jumbo {
  @extend h1;
  font-size: 2.2rem;

  @include media-breakpoint-up(lg) {
    font-size: 2.6rem;
  }
}

.headline-1 {
  @extend h1;
}

.headline-2 {
  @extend h2;
}

.headline-3 {
  @extend h3;
}

.headline-4 {
  @extend h4;
}

.headline-5 {
  @extend h5;
}

.headline-6 {
  @extend h6;
}

p {
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1.0125rem;
  color: $newslettergrey;
}

ul li {
  font-size: 1.0125rem;
}
