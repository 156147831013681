.product-hero {
  @include white-headers;

  background: $blue;

  .youtube-video {
    max-width: 100%;

    @include media-breakpoint-down(sm) {
      height: auto;
    }
  }

  .container {
    max-width: 27.5rem;
    @include media-breakpoint-between(sm, lg) {
      max-width: 37rem;
    }

    @include media-breakpoint-between(lg, xl) {
      max-width: 48rem;
    }
  }

  .main {
    //padding-right: 0;
    z-index: 100;
  }

  @include media-breakpoint-up(xl) {
    .container {
      max-width: 71rem;
      margin-inline: auto;
      padding: 0;
    }

    .text-left {
      max-width: 29.265rem;
      padding-left: 0;
    }
  }
}
