.name-with-date {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  vertical-align: center;

  p {
    display: flex;
    font-size: 0.75rem;
    color: $black;
    font-weight: 800;
    padding-top: 6px;

    img {
      width: 1.875rem;
      height: 1.875rem;
      border-radius: 50%;
      margin-right: 0.5rem;
    }
  }

  .date {
    font-size: 0.75rem;
    color: $black;
    font-weight: 800;
  }
}
