.breadcrumb-container {
  padding-bottom: 0;
}

.breadcrumb {
  margin-top: 1rem;
  color: $grey;
  font-size: 14px;

  a {
    color: $grey;
  }
}
