section.page {
  @include blue-headers;

  h3 {
    @include font-size($h4-font-size-mobile);

    @include media-breakpoint-up(lg) {
      @include font-size($h4-font-size);
    }

    margin-top: 1rem;
    color: $blue;
  }

  h1, h2, h3, h4, h5, h6 {
    text-align: center;
  }
}

.page-conditions {


  .container {
    margin-top: 0;
    margin-bottom: 0;
  }

}
