.background {

  .container {
    padding: 1.4375rem 0;
  }

  width: 100%;
  background: $orange;

  .text-center {
    text-align: center;

    p {
      font-size: 1rem;
      font-weight: 300;
      font-style: normal;
      line-height: 1.40625rem;
      margin: 0;
    }
  }

  .text-center > p > strong {
    color: $black;
    font-weight: 600;
  }

  .text-center > p > a {
    font-size: 1rem;
    color: $blue;
    font-weight: 500;
    text-decoration: none;
  }

  @media (max-width: 700px) {
    .container {
      padding: 0.75rem 0;
    }

    .text-center > p {
      font-size: 0.875rem;
    }

    .text-center > p > a {
      display: inline-block;
      font-weight: 400;
      font-size: 0.875rem;
    }

    .text-center > p > strong {
      font-weight: 600;
    }
  }

  @media (min-width: 700px) {
    .text-center > p > br {
      display: none;
    }
  }
}
