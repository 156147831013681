.contact-us {
  @include white-headers;
  background: $blue;

  .container {
    background: url('/images/bg-circle.svg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    margin-top: 0;
    margin-bottom: 0;

    .left {
      h4 {
        color: $secondary;
        font-weight: 600;
        font-size: 1.2rem;
        margin-top: 1.5rem;
        margin-bottom: 1rem;

        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 2rem;
          margin-top: 2.2rem;
          margin-bottom: 2rem;
        }
      }

      p {
        color: $white;
      }

      ul {
        color: $white;
        padding-left: 0;
        list-style-type: none;
        margin-bottom: 1rem;

        li {
          font-size: 1.2rem;
          line-height: 1.5rem;
          list-style: none;
          margin-bottom: 0.5rem;
          font-weight: 300;
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          align-content: center;
          flex-direction: row;

          &::before {
            background-image: url(/images/icons/check-circle-1.svg);
            background-repeat: no-repeat;
            background-size: cover;
            content: '';
            display: inline-block;
            width: 30px;
            height: 30px;

            @include media-breakpoint-up(xl) {
              width: 35px;
              height: 35px;
            }

          }

          @include media-breakpoint-up(lg) {
            font-size: 1.1rem;
            margin-bottom: 0.7rem;
          }

          @include media-breakpoint-up(xl) {
            font-size: 1.2rem;
            margin-bottom: 0.8rem;
          }

          @include media-breakpoint-up(xxl) {
            font-size: 1.3rem;
            margin-bottom: 1.2rem;
          }

          p {
            margin-bottom: 0;
            line-height: 1.7rem;
          }
        }
      }
    }

    .right {
      justify-content: center;
      align-items: center;
      display: flex;

      .card {
        width: 100%;

        @include media-breakpoint-up(lg) {
          max-width: 350px;
        }

        @include media-breakpoint-up(xl) {
          max-width: 450px;
        }
      }

      .icon {
        width: 30px;

        @include media-breakpoint-up(xl) {
          width: 35px;
        }
      }

      h4 {
        color: $darkgrey;
        font-size: 1.4rem;
        margin-top: 1.5rem;
        margin-bottom: 0;
        line-height: 2rem;

        @include media-breakpoint-up(lg) {
          font-size: 1.5rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 1.6rem;
          margin-top: 2rem;
          line-height: 2.2rem;
        }

        @include media-breakpoint-up(xxl) {
          font-size: 2rem;
          margin-top: 2.2rem;
          line-height: 2.7rem;
          margin-bottom: 1rem;
        }

        a {
          color: $primary;
        }
      }
    }

  }
}
