.page-menu,
.product-anchors {
  @include white-headers;

  background-color: $white;
  padding: 0.7rem 0.5rem 0;

  .container-page-menu {
    padding-bottom: 0;
  }

  @media (min-width: 200px) and (max-width: 961px) {
    .col {
      text-align: left !important;
      flex-basis: auto;
      -webkit-box-flex: 1;
      flex-grow: 1;
      max-width: 50%;
    }
  }
  .anchors {
    position: relative;
    color: $black;
    text-decoration: none;
    text-align: center;
    @include media-breakpoint-up(lg) {
      right: 1rem;
    }
  }

  a {
    color: $black;
    font-size: 1rem;
    font-weight: 700;
    text-transform: uppercase;
    @include media-breakpoint-down(xl) {
      font-size: 0.8rem;
    }
  }

  a[data-href] {
    text-transform: lowercase;
  }

  a:hover {
    color: $blue;
  }

  a > .page-menu-img {
    margin-top: 0.45rem;
    margin-bottom: 0.75rem;
    height: 1.675rem;
    display: inline-block;
    @include media-breakpoint-down(lg) {
      height: 1rem;
    }
  }

  span {
    text-transform: capitalize !important;
    background-color: $blue !important;
  }

  .badge {
    border-radius: 0.31rem;
    position: absolute;
    top: -23px;
    right: -42%;
    @include media-breakpoint-down(xl) {
      top: -16px;
      right: 38%;
    }
  }

  @include media-breakpoint-up(md) {
    a > .page-menu-img {
      height: 2rem;
    }
  }
  .row {
    @include media-breakpoint-up(xl) {
      margin-left: -7%;
      margin-right: -28%;
    }
    @include media-breakpoint-only(md) {
      padding-left: 3.5rem;
    }
    @include media-breakpoint-down(sm) {
      padding-left: 1rem;
    }
  }

  @include media-breakpoint-up(xl) {
    .product-anchors,
    .product-menu > .container,
    .container-product-menu {
      max-width: 71.5rem;
    }
  }
}
