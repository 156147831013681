section.faqs {

  .accordions-wrapper {
    flex-direction: column;
    gap: 0;
    @include media-breakpoint-up(lg) {
      flex-direction: row;
      gap: 1rem;
    }

    .accordion {
      width: 100%;
      @include media-breakpoint-up(lg) {
        width: 50%;
      }

      .accordion-item {
        cursor: pointer;
        margin-bottom: 1rem;

        @include border-top-radius($accordion-border-radius);
        @include border-bottom-radius($accordion-inner-border-radius);

        &:not(:first-of-type) {
          border-top: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
        }

        button {
          cursor: pointer;
        }
      }

      .accordion-body {
        padding: 1.5rem 1.5rem 0.5rem;
        color: $normalgrey;

        p {
          font-size: $font-size-base * 0.8;
        }
      }

      .accordion-button {
        @include border-top-radius($accordion-border-radius);
        @include border-bottom-radius($accordion-inner-border-radius);

        padding: 1rem 1.52rem 0.8rem;
        color: $normalgrey;
        font-size: $font-size-base * 0.8;

        --bs-accordion-btn-icon: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='%23686868' d='M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z'/></svg>");
        --bs-accordion-btn-active-icon: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><path fill='%23007fff' d='M432 256c0 17.7-14.3 32-32 32L48 288c-17.7 0-32-14.3-32-32s14.3-32 32-32l352 0c17.7 0 32 14.3 32 32z'/></svg>");
        --bs-accordion-btn-icon-transform: none;
        --bs-accordion-btn-icon-width: 16px;
        --bs-accordion-btn-icon-height: 16px;

        &::after {
          margin-right: -0.5rem;
          margin-top: -4px;
          color: $normalgrey;
        }

        &:not(.collapsed) {
          color: $blue;

          &::after {
            margin-right: -0.5rem;
            margin-top: 2px;
            margin-bottom: auto;
            color: $blue;
          }
        }
      }
    }
  }
}
