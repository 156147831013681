h1, h2, h3, h4, h5 {
  color: $darkgrey;
  letter-spacing: 0em;
  font-style: normal;

  strong {
    color: $secondary;
  }

  a {
    text-decoration: underline;
    color: $dark-blue;
  }
}


@mixin white-headers {
  h1,
  h2,
  h3,
  h4,
  h5,
  .headline-1,
  .headline-2,
  .headline-3,
  .headline-4,
  .headline-5 {
    color: $white;

    a {
      text-decoration: none;
      color: $secondary;
    }

    p {
      color: #686868;
    }

  }

  ul, li, ol {
    color: $white;
  }
}

@mixin blue-headers {
  h1 {
    color: $dark-blue;
  }
  h2 {
    color: $dark-blue;
  }
  h3 {
    color: $dark-blue;
  }
  h4 {
    color: $dark-blue;
  }
  h5 {
    a {
      text-decoration: none;
      color: $dark-blue;
    }
  }
}

@mixin blue-orange {
  h1,
  h2 {
    color: $dark-blue;
  }

  h3 {
    color: #565c65;
  }

  span {
    color: $orange;
  }
}

@mixin blogCard {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  h2 {
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 600;
    line-height: 2.125rem;
    letter-spacing: 0em;
    text-align: left;
    color: $darkerBlack;
  }

  p.card-text {
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3125rem;
    letter-spacing: 0em;
    text-align: left;
    color: $normalgrey;
  }

  .readMore > a {
    text-decoration: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
  }
}

.headline {
  @extend .headline-3;
  font-weight: 200;
}

@mixin fonts {
}
