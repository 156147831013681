/* *
* TOP Promo
*/
.top-promo {
  font-weight: 600;
  text-align: center;
  color: $white;
  background-color: $orange;
  transition: 200ms;
  font-size: $font-size-sm;

  @include spacing-horizontal(1.5rem);
  @include spacing-vertical(1rem);

  &.scrolled {
    padding: 0rem;
    height: 0;
  }

  @include media-breakpoint-up(lg) {
    @include spacing-horizontal(3rem);
    @include spacing-vertical(1rem);

    &.top-promo-scrolled {
      padding: 0rem;
    }
  }

  a {
    color: $primary;
    font-weight: bold;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
