@mixin read {
  .readMore {
    a {
      text-decoration: none;
      color: $blue;
      letter-spacing: 0em;

      &:hover {
        text-decoration: underline;
      }
    }

  }
}
