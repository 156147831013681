section.roadmap {
  background: $lightest;
  text-align: center;
  @include blue-headers;

  .frame {
    @include spacing-vertical($spacing-vertical);

    iframe {
      box-shadow: #0000001f 1px 1px 6px 2px;
      border-radius: $border-radius;
    }
  }
}
