strong {
  font-weight: bold;
}

ul {
  &.mark-check {
    list-style-type: none; /* Remove default bullet points */
    padding-left: 0; /* Remove default left padding for list items */

    li {
      display: flex;
      align-items: start;
      line-height: 1.5rem;
      text-align: left;
    }

    li::before {
      background-image: url("/images/_icons/Icon.svg");
      background-repeat: no-repeat;
      width: 18px;
      min-width: 18px;
      height: 22px;
      content: " ";
      display: inline-block;
      margin-right: 0.25rem; /* Add spacing between the check mark and the list item text */
      position: relative;
      top: 2px;
      background-size: contain;
    }

    &.white {
      li::before {
        background-image: url("/images/_icons/Icon-white.svg");
      }
    }

    &.blue {
      color: $blue;

      li::before {
        background-color: $blue;
        mask-image: url('/images/_icons/Icon-current.svg');
      }
    }

    &.gray {
      color: $normalgrey;

      li::before {
        background-color: $normalgrey;
        mask-image: url('/images/_icons/Icon-current.svg');
      }
    }
  }
}

@mixin spacing-horizontal($space) {
  padding-left: $space;
  padding-right: $space;
}

@mixin spacing-vertical($space) {
  padding-top: $space;
  padding-bottom: $space;
}

section {
  background: $white;
  //padding: 2rem 0;

  &.spaced {
    @include spacing-vertical($spacing-vertical-mobile);

    @include media-breakpoint-up(lg) {
      @include spacing-vertical($spacing-vertical);
    }
  }
}
