section.pricing-selector {
  background: $lightest;
  text-align: center;
  @include blue-headers;

  .clickable {
    cursor: pointer;

    span {
      color: $dark-blue;
      //font-weight: bold;
    }

    &:hover {
      text-decoration: underline;
    }
  }


  .card.pricing-card {
    color: $normalgrey;
    width: 100%;
    max-width: 360px;

    h1 {
      color: $dark-blue;
      font-size: $h1-font-size;
    }

    .fine-print {
      font-size: 0.8rem;
      line-height: 1.4rem;
    }
  }

  .features-card {
    width: 600px;
    max-width: 100%;
    flex-direction: column;
    justify-content: space-around;
    padding: 1.5rem;
    text-align: left;
    gap: 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: 1rem;
    }

    ul {
      margin-bottom: 0;

      li {
        line-height: 2rem;

        &::before {
          top: 8px;
        }
      }
    }
  }
}
