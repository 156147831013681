@use 'sass:map';

.footer {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  text-align: left;
  background-color: $darkgrey;
  padding: map.get($spacers, 5) 1rem map.get($spacers, 4);

  @include media-breakpoint-up(md) {
    text-align: center;
  }

  h5 {
    color: $white;
    font-size: 1.25rem;
    line-height: 2rem;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  ul {
    padding-left: 0;

    li {
      display: block;
      list-style-type: none;
      margin-bottom: 0.5rem;

      a {
        text-decoration: none;
        display: block;
        color: $footergrey;
        font-size: $font-size-sm;
        font-weight: 400;

        &:hover {
          color: $white;
        }
      }
    }

    &.socials {
      position: relative;
      display: flex;
      align-items: center;
      left: 50%;
      transform: translateX(-50%);

      li {
        a {
          width: 2em;
          height: 2em;
          margin: 0;
        }

        .middleOne {
          margin-left: 1rem;
          margin-right: 1rem;
        }
      }

      @include media-breakpoint-up(lg) {
        left: 0;
        transform: translateX(0);
      }
    }
  }

  p {
    color: $footergrey;
    font-size: 0.75rem;
    font-weight: 400;
  }

  .icon-white {
    svg path {
      display: inline;
      fill: transparent;
    }

    svg:hover path {
      fill: $white;
    }
  }

  .text-center {
    margin-top: 3rem;
  }

  .newsletter {
    .form-control {
      height: 2.5rem;
      padding: 0 0.75rem;
    }

    input {
      background: $normalgrey;
      color: #bdbdbd;
      border: 0 none;
    }

    button {
      background: $normalgrey;
      border-radius: 0 $border-radius $border-radius 0;
      padding: 0.4375rem 1rem;
      height: 2.5rem;
    }

    width: 13.3125rem;
    height: 2.5rem;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    @include media-breakpoint-up(xl) {
      left: 0;
      transform: translateX(0);

      input {
        background: $newslettergrey;
      }

      button {
        background: $newslettergrey;
      }

      .icon-grey > svg:hover path {
        fill: $grey;
      }

      .icon-white {
        display: none;
      }
    }
  }

  .terms-div {
    @media (min-width: 992px) and (max-width: 1199px) {
      margin-top: 1.125rem;
    }

    img {
      background: $white;
      margin-bottom: 0.5rem;
    }
  }

  .img-div {
    padding-bottom: 0.5rem;

    .img-logo {
      width: 130px;
      background-color: transparent;
    }
  }

  @include media-breakpoint-up(xl) {
    .container-fluid {
      max-width: 70rem;
    }

    .last-column {
      padding-left: 3rem;
    }
  }

  @include media-breakpoint-down(xl) {
    .icon-grey {
      display: none;
    }

    h5 {
      margin-top: 1.3rem;
      margin-bottom: 1.3rem;
    }
  }
}
