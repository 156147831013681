section.client-logo {
  .headline-5 {
    text-align: center;
    color: $dark-blue;
  }

  .logo {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    align-items: center;
    padding: 1rem;

    a, div {
      max-width: 140px;
      max-height: 140px;

      img {
        max-height: 50px;
        transition: all 300ms ease;
        filter: saturate(0%) brightness(70%);

        &:hover {
          filter: saturate(100%) brightness(100%);
        }
      }
    }
  }
}
