section.text-with-image {
  @include blue-orange;
  @include read;

  margin-top: 2rem;

  @include media-breakpoint-up(xxl) {
    margin-top: 0;
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 2rem;
  }

  .flex-row-reverse {
    .readMore {
      text-align: left;
    }
  }

  .readMore {
    text-align: right;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-top: 2rem;
    }
  }

  .box-text {
    margin-top: 2rem;

    @include media-breakpoint-up(lg) {
      margin-top: 0;
    }

    p {
      margin-bottom: 0.25rem;
    }

    li {
      margin-bottom: 0.25rem;
    }
  }

  .box-img {
    @include media-breakpoint-up(lg) {
      padding-left: 4rem;
      padding-right: 4rem;
    }
  }

  .box-text, .box-img {
    text-align: left;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;


    h2 {
      @include media-breakpoint-up(xl) {
        margin-bottom: 0.5rem;
      }
    }
  }

  .box-img {

    .img-bg {
      padding: 12px;
      border-radius: 24px;
      box-shadow: 1px 1px 8px #ededed;

      @include media-breakpoint-up(xl) {
        padding: 20px;
      }

      img {
        border-radius: 16px;
        border: 1px solid #ededed;
      }
    }
  }

}
