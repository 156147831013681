.container {
  margin-top: 0;
  margin-bottom: 0;

  @include spacing-horizontal(1.5rem);

  @include media-breakpoint-up(lg) {
    @include spacing-horizontal(3rem);
  }
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

.hides-hero-section {
  z-index: 1;
  position: relative;
}

.text-highlight-yellow {
  color: #f6cc5c;
}

.text-highlight-darkblue {
  color: #003366;
}

.text-highlight-blue {
  color: $blue;
}

section.bg-icons {
  background-repeat: repeat;
  background-position: top;
}
