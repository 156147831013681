.btn {
  /*
  box-shadow: 0 2px 0 0 rgba(0, 0, 0, 0.1);
   */
  padding: 0.8rem 1.5rem;
  font-size: 0.875rem;

  &.btn-success {
    &,
    &:hover,
    &:focus,
    &:active {
      color: $white;
    }
  }

  &.btn-outline-success, &.btn-outline-highlight, &.btn-primary {
    &:hover,
    &:focus,
    &:active {
      color: $white !important;
    }
  }
}


.btn-cl-primary {
  --bs-btn-font-weight: 800;
  --bs-btn-color: var(--bs-white);
  --bs-btn-bg: #{shade-color($orange, 5%)};
  --bs-btn-border-color: #{shade-color($orange, 5%)};
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($orange, 15%)};
  --bs-btn-hover-border-color: #{shade-color($orange, 10%)};
  --bs-btn-focus-shadow-rgb: #{shade-color($orange, 5%)};
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($orange, 20%)};
  --bs-btn-active-border-color: #{shade-color($orange, 20%)};
}

.btn-cl-outline-primary {
  --bs-btn-font-weight: 800;
  --bs-btn-color: #{shade-color($orange, 5%)};
  --bs-btn-bg: var(--bs-white);
  --bs-btn-border-color: #{shade-color($orange, 5%)};
  --bs-btn-hover-color: var(--bs-white);
  --bs-btn-hover-bg: #{shade-color($orange, 10%)};
  --bs-btn-hover-border-color: #{shade-color($orange, 10%)};
  --bs-btn-focus-shadow-rgb: #{shade-color($orange, 5%)};
  --bs-btn-active-color: var(--bs-btn-hover-color);
  --bs-btn-active-bg: #{shade-color($orange, 20%)};
  --bs-btn-active-border-color: #{shade-color($orange, 20%)};
}

.btn-flex {
  display: flex;
  align-items: center;
}
